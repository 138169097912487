import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {AutoUnsubscribables, AutoUnsubscriber, cad, ApplicationVersionService} from "@comm-apps/common";
import * as _ from 'lodash-es';
import Environment = cad.Environment;


@Component({
  selector: 'cad-lib-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  @AutoUnsubscriber()
  private subs: AutoUnsubscribables;
  
  public appVersion: string = 'Loading...';
  public hasNewUIVersion: boolean = null;

  constructor(
    public dialogRef: MatDialogRef<AboutComponent>, 
    @Inject(MAT_DIALOG_DATA) 
    public data: any, 
    public dialog: MatDialog, 
    public appVersionService: ApplicationVersionService,
    @Inject('environment') public env: Environment) {

  }

  ngOnInit() {
    this.getVersion();
    this.checkUIVersion();
  }

  private getVersion(): void  {
    const notAvailable = 'Not Available';
    this.subs.newSub = this.appVersionService.getApplicationVersion().pipe().subscribe(version => {
      this.appVersion = version && !version.trim().startsWith('<') ? version : notAvailable;
    }, (error) => {
      this.appVersion = notAvailable;
    })
  }

  private checkUIVersion(): void {
    this.subs.newSub = this.appVersionService.hasNewUIVersion().subscribe((hasNewVersion) => {
      this.hasNewUIVersion = hasNewVersion;
    });
  }

  public close(): void {
    this.dialogRef.close(null);
  }

}
