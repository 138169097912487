import {Component, Inject, OnInit} from '@angular/core';
import {NewVersionSnackbarComponent} from '../new-version-snackbar/new-version-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import {cad, ApplicationVersionService } from '@comm-apps/common';
import Environment = cad.Environment;

@Component({
  selector: 'cad-lib-version-checker',
  template: ``,
  styles: []
})
export class VersionCheckerComponent implements OnInit {

  private displayTime: number = 60000;
  private checkInterval: number = 240000;

  constructor(
    private versionService: ApplicationVersionService,
    private matSnackBar: MatSnackBar,
    @Inject('environment') public env: Environment
  ) { }

  ngOnInit() {
    if (this.env.localDevelopment) {
      return;
    }
      setTimeout(() => {
        this.checkForNewVersion();
      }, 6000);
  }

  public checkForNewVersion(): void {
    this.versionService.hasNewUIVersion().subscribe((newVersionAvailable) => {
      
        if (newVersionAvailable) {
          let sbRef = this.matSnackBar.openFromComponent(NewVersionSnackbarComponent, {duration: this.displayTime});
          sbRef.instance.sbRef = sbRef;
        }
    });

    setTimeout(() => {
      this.checkForNewVersion();
    }, this.checkInterval);

  }

}
