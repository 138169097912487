import {Component, EventEmitter, Inject, Input, OnInit, Optional, Output, OnChanges, SimpleChange, SimpleChanges, ChangeDetectorRef, HostListener} from '@angular/core';
import { AutoUnsubscribables, AutoUnsubscriber } from '@comm-apps/common';
import { MatDialog } from '@angular/material/dialog';
import {RunAsUserComponent} from '../../dialogs/run-as-user/run-as-user.component';
import {CadLoginDialogService} from '../../dialogs/login/cad-login-dialog.service';
import {CadLoginCallbackService, LOGIN_SERVICE} from '../../dialogs/login/cad-login-service-callback.interface';
import {DefaultUserService} from '../../dialogs/login/default-user.service';
import { Router } from '@angular/router';
import { AboutComponent } from '../../dialogs/about/about.component';

@Component({
  selector: 'cad-lib-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  constructor(public dialog: MatDialog, private loginSvc: CadLoginDialogService,
              private defaultLoginService: DefaultUserService,
              private router: Router,
              @Inject(LOGIN_SERVICE) @Optional() protected loginService: CadLoginCallbackService) {
    if (this.loginService == null) {
      this.loginService = this.defaultLoginService
    }
  }
  @AutoUnsubscriber()
  private subs: AutoUnsubscribables;

  @Input()
  userName: string;

  @Input()
  runAsUserOption: boolean = false;

  @Input()
  aboutOption: boolean = true;

  @Input()
  helpPath: string = '/help';

  @Output()
  helpClicked = new EventEmitter<any>();

  @Input()
  settingsPath: string;

  @Input()
  profilePath: string = '/user-profile';

  @Output()
  loginComplete = new EventEmitter<any>();
  @Output()
  logoutComplete = new EventEmitter<any>();

  @Output()
  runAsComplete = new EventEmitter<string>();
  @Output()
  stopRunAsComplete = new EventEmitter<string>();

  public runAsUserName: string;

  ngOnInit() {
    this.runAsUserName = localStorage.getItem('X-On-Behalf-Of');
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'F1') {
      if (this.helpPath) {
        this.router.navigateByUrl(this.helpPath);
      } else {
        this.help();
      }
      return false;
    }
  }

  public login(): void {
    const sub = this.loginSvc.login().subscribe(result => {
      if (result !== 'Cancel') {
        this.loginComplete.emit(result);
      }
    }, null, () => sub.unsubscribe());
  }

  public logout(): void {
    const sub = this.loginService.logoutUser().subscribe(success => {
      localStorage.removeItem('Auth');
      this.userName = null;
      this.logoutComplete.emit('Logout');
    }, null, () => sub.unsubscribe());
  }

  public runAsUser(): void {
    const dialogRef = this.dialog.open(RunAsUserComponent, {
      height: '250px',
      width: '300px'
    });

    const sub = dialogRef.afterClosed().subscribe(result => {
      if (result !== 'Cancel') {
        this.runAsUserName = result;
        this.runAsComplete.emit(result);
      }
      sub.unsubscribe();
    });
  }

  public stopRunAsUser(): void {
    localStorage.removeItem('X-On-Behalf-Of');
    this.runAsUserName = null;
    this.stopRunAsComplete.emit(this.runAsUserName);
  }

  public help(): void {
    this.helpClicked.emit('help');
  }

  public about(): void {
    const dialogRef = this.dialog.open(AboutComponent, {
      height: '285px',
      width: '400px'
    });
  }
}
