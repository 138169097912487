import {Injectable} from '@angular/core';
import {HttpHelperService} from '@comm-apps/http';
import {Observable, map, take, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplicationVersionService {

  private endpoint: string = 'public-api/version';
  private versioningScripts: string[] = ['main', 'runtime', 'polyfills'];

  constructor(private apiHelper: HttpHelperService, private httpClient: HttpHelperService) {
  }

  getApplicationVersion(): Observable<string> {
    return this.apiHelper.request(`${this.endpoint}`, { responseType: 'text' });
  }

  public hasNewUIVersion(): Observable<boolean> {
    return this.httpClient.request('index.html?unique=' + (new Date()).getTime(), {responseType:'text'}).pipe(map((text) => {
      let nodeList: NodeListOf<Element> = document.querySelectorAll('body script[src]');

      let foundDiff: boolean = false;

      //iterate over the script elements and grab the source attribute.  if it matches the index file we just downloaded, there is NOT a new version
      nodeList.forEach((element: Element) => {
        if (!foundDiff) {
          let searchVal = element.getAttribute('src');
          searchVal = searchVal.substring(searchVal.lastIndexOf('/'));
          if (this.srcQualifies(searchVal) && text.indexOf(searchVal) === -1) {
            foundDiff = true;
          }
        }
      })

      return foundDiff;
    }), take(1));
  }

   /**
   * Looks at the list of script starts with names from the versioningScripts array and lets us know if it is a script we want to look at.
   */
  private srcQualifies(src: string): boolean {
    let qualifies: boolean = false;
    this.versioningScripts.forEach((val) => {
      if (src.startsWith(val)) {
        qualifies = true;
      }
    });
    return qualifies;
  }
  
}
